import './filter.scss';
import Swiper, { Navigation } from 'swiper';
import axios from 'axios';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createElementFromHTML, getFormData } from './../../utilities/js/helper';
import { ResizeHandler } from './../../utilities/js/resize-handler';

class Filter {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-filter',
            allowUpdateUri: true,
            filterAttr: 'filter',
            filterBarAttr: 'bar',
            filterBarScrollerAttr: 'bar-scroller',
            filterBarItemsAttr: 'bar-items',
            barItemAttr: 'bar-item',
            barButtonsAttr: 'bar-buttons',
            selectedAttr: 'selected',
            resultsAttr: 'results',
            toggleAttr: 'toggle',
            groupsAttr: 'groups',
            groupToggleAttr: 'group-toggle',
            groupHandleAttr: 'group-handle',
            comboAttr: 'combo',
            closeAttr: 'close',
            removeAttr: 'remove'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$filter = $element;
        this.$filterToggle = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$results = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.resultsAttr + '"]');
        this.$filterBar = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarAttr + '"]');
        this.$filterItems = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterAttr + '"]');
        this.$filterSelected = this.$filter.querySelector('[' + this.settings.initAttr + '="' + this.settings.selectedAttr + '"]');
        this.$filterCombos = this.$filter.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.comboAttr + '"]');
        this.filterUri = this.$filter.getAttribute('action');
        this.$siteHeader = document.querySelector('[data-siteheader="root"]');
        this.$loadingSpinner = createElementFromHTML(window.OPTIONS.loader);
        this.currentComboBoxOpened = null;

        this.allowUpdateUri = this.settings.allowUpdateUri;
        if (this.$filter.getAttribute('no-update-uri') !== null) {
            this.allowUpdateUri = false;
        }

        this.documentClickTimeout = null;
        this.filterOpen = false;
        this.filterBarSlider = null;
        this.filterBarButtons = false;

        this.resizeHandler = new ResizeHandler();
        this.resizeHandler.init();

        this.initialize();
    }

    initialize () {
        this.setEvents();

        if (this.$filterBar) {
            this.handleFilterBar();
        }

        // close current combo on initial deeplink call
        if (this.$filter.querySelector('[' + this.settings.initAttr + '="combo-toggle"]:checked')) {
            this.currentComboBoxOpened = this.$filter.querySelector('[' + this.settings.initAttr + '="combo-toggle"]:checked').value;
            this.closeCombo();
        }
    }

    setEvents () {
        if (this.$siteHeader) {
            if (this.$filterToggle) {
                this.$filterToggle.addEventListener('change', () => {
                    clearTimeout(this.documentClickTimeout);

                    if (this.$filterToggle.checked === true) {
                        this.$siteHeader.classList.add('siteheader--filter-open');
                        disableBodyScroll(this.$filterItems);

                        this.documentClickTimeout = window.setTimeout(() => {
                            this.filterOpen = true;
                        }, 500);
                    } else {
                        this.$siteHeader.classList.remove('siteheader--filter-open');
                        enableBodyScroll(this.$filterItems);
                        this.filterOpen = false;
                    }
                });
            }
        }

        this.$filterItems.addEventListener('change', (e) => {
            const $element = e.target;
            if ($element.getAttribute('formtrigger') === 'ignore') {
                e.preventDefault();
            } else {
                this.updateList();
            }

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.groupToggleAttr + '"]')) {
                if ($element.checked === true) {
                    $element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }

            // check if combo click
            if ($element.getAttribute(this.settings.initAttr) === 'combo-toggle') {
                if ($element.checked === true) {
                    if (this.currentComboBoxOpened !== null) {
                        this.closeCombo();
                    }
                    this.currentComboBoxOpened = $element.value;
                } else {
                    this.currentComboBoxOpened = null;
                }
            }
        });

        this.$filterItems.addEventListener('click', (e) => {
            const $element = e.target;

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]')) {
                this.$filterToggle.checked = false;

                const toggleUpdateEvent = new CustomEvent('change', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                this.$filterToggle.dispatchEvent(toggleUpdateEvent);

                e.preventDefault();
            }

            if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.groupHandleAttr + '"]')) {
                const $target = document.getElementById($element.getAttribute('for'));
                if ($target.checked === true) {
                    $target.checked = false;
                } else {
                    $target.checked = true;
                }

                const targetUpdateEvent = new CustomEvent('change', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });

                $target.dispatchEvent(targetUpdateEvent);

                e.preventDefault();
            }
        });

        if (this.$filterBar) {
            this.$filterBar.addEventListener('click', (e) => {
                const $element = e.target;
                if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.barItemAttr + '"]') !== null) {
                    if (this.$filterToggle.checked === false) {
                        this.$filterToggle.checked = true;

                        const toggleUpdateEvent = new CustomEvent('change', {
                            view: window,
                            bubbles: true,
                            cancelable: false
                        });
                        this.$filterToggle.dispatchEvent(toggleUpdateEvent);
                    }
                }

                if ($element.closest('[' + this.settings.initAttr + '="' + this.settings.removeAttr + '"]') !== null) {
                    const $currentFilter = this.$filterItems.querySelectorAll('input:checked:not([formtrigger="ignore"])');

                    for (let i = 0; i < $currentFilter.length; i++) {
                        $currentFilter[i].checked = false;

                        if (i === $currentFilter.length - 1) {
                            const filterUpdateEvent = new CustomEvent('change', {
                                view: window,
                                bubbles: true,
                                cancelable: false
                            });
                            this.$filterItems.dispatchEvent(filterUpdateEvent);
                        }
                    }
                }
            });
        }

        this.resizeHandler.customFunctions.push(() => {
            if (this.$filterBar) {
                this.handleFilterBar();
            }
        });

        document.addEventListener('click', (e) => {
            if (this.filterOpen === true) {
                if (!this.$filterItems.contains(e.target) && e.target !== this.$filterItems) {
                    this.closeFilter();

                    e.preventDefault();
                }
            }

            // check if click in opened combo box
            const $comboBox = e.target.closest(`[${this.settings.initAttr}="${this.settings.comboAttr}"]`);
            if (!$comboBox && this.currentComboBoxOpened !== null) {
                this.closeCombo();
            }
        });
    }

    closeCombo () {
        const $comboToggle = this.$filter.querySelector(`[${this.settings.initAttr}="combo-toggle"][value="${this.currentComboBoxOpened}"]`);
        $comboToggle.checked = false;
        this.currentComboBoxOpened = null;
    }

    closeFilter () {
        this.$filterToggle.checked = false;

        const toggleUpdateEvent = new CustomEvent('change', {
            view: window,
            bubbles: true,
            cancelable: false
        });
        this.$filterToggle.dispatchEvent(toggleUpdateEvent);
    }

    initFilterBarSlider () {
        const $filterBarScroller = this.$filterBar.querySelector(`[${this.settings.initAttr}="${this.settings.filterBarScrollerAttr}"]`);
        const $filterBarButtonsTemplate = this.$filterBar.querySelector(`[${this.settings.initAttr}="${this.settings.barButtonsAttr}"]`);
        const $filterBarButtons = $filterBarButtonsTemplate.content.cloneNode(true);
        $filterBarScroller.appendChild($filterBarButtons);

        this.$filterBar.classList.add('filter-bar--scrollable');

        this.filterBarSlider = new Swiper($filterBarScroller, {
            modules: [Navigation],
            speed: 500,
            slidesPerView: 'auto',
            wrapperClass: 'filter-bar__items',
            slideClass: 'filter-bar__item',
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    destroyFilterBarSlider () {
        this.$filterBar.classList.remove('filter-bar--scrollable');

        if (this.filterBarSlider !== null) {
            this.filterBarSlider.destroy(true, true);
            this.filterBarSlider = null;

            let $filterBarButtons = this.$filterBar.querySelector('.swiper-buttons');

            if ($filterBarButtons !== null) {
                $filterBarButtons.parentNode.removeChild($filterBarButtons);
                $filterBarButtons = null;
            }
        }
    }

    handleFilterBar () {
        const $filterBarItems = this.$filterBar.querySelector(`[${this.settings.initAttr}="${this.settings.filterBarItemsAttr}"]`);

        const filterBarItemsWidth = $filterBarItems.offsetWidth;
        const filterBarItemsScrollWidth = $filterBarItems.scrollWidth;

        if (filterBarItemsScrollWidth > filterBarItemsWidth && window.mediaMatchHandler[1024].matches) {
            if (this.filterBarButtons === false) {
                this.initFilterBarSlider();
                this.filterBarButtons = true;
            }
        } else {
            this.destroyFilterBarSlider();
            this.filterBarButtons = false;
        }
    }

    updateUri (formData) {
        if (this.allowUpdateUri === true) {
            let uri = this.filterUri;

            const serializedData = Array.from(
                formData,
                e => e.map(encodeURIComponent).join('=')
            ).join('&');

            if (serializedData) {
                uri = uri + '?' + serializedData;
            }

            this.setHistory(uri);
        }
    }

    updateList () {
        const formData = getFormData(this.$filterItems);

        this.updateUri(formData);

        if (this.$filterItems) {
            this.$filterItems.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$results) {
            this.$results.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$filterSelected) {
            this.$filterSelected.appendChild(this.$loadingSpinner.cloneNode(true));
        }
        if (this.$filterBar) {
            this.$filterBar.appendChild(this.$loadingSpinner);
        }

        axios({
            method: 'POST',
            url: this.filterUri,
            data: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then((result) => {
                if (result && result.data) {
                    const $result = createElementFromHTML(result.data);

                    if (this.$filterItems) {
                        const $filterResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterAttr + '"]');
                        this.$filterItems.innerHTML = $filterResult.innerHTML;
                    }

                    if (this.$results) {
                        const $itemsResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.resultsAttr + '"]');
                        this.$results.innerHTML = $itemsResult.innerHTML;
                    }

                    if (this.$filterBar) {
                        const $filterBarResult = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.filterBarAttr + '"]');
                        this.$filterBar.innerHTML = $filterBarResult.innerHTML;
                    }

                    if (this.$filterSelected) {
                        const $filterSelected = $result.querySelector('[' + this.settings.initAttr + '="' + this.settings.selectedAttr + '"]');
                        this.$filterSelected.innerHTML = $filterSelected.innerHTML;
                    }

                    if (!this.$filterItems.querySelector('[' + this.settings.initAttr + '="combo-toggle"]:checked')) {
                        this.currentComboBoxOpened = null;
                    }

                    const contentUpdateEvent = new CustomEvent('content.loaded', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: this.$results
                        }
                    });
                    window.dispatchEvent(contentUpdateEvent);

                    window.checkInview(window.eventScroller);

                    if (this.$filterBar) {
                        this.filterBarButtons = false;
                        this.handleFilterBar();
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    setHistory (uri) {
        history.pushState(null, document.title, uri);
    }
}

export { Filter };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$filter = $context.querySelectorAll('[data-filter="root"]');
        for (let i = 0; i < $$filter.length; i++) {
            const $filter = $$filter[i];

            const filterAPI = new Filter($filter);
            $filter.API = filterAPI;
        }
    }
});
